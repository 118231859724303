@font-face {
    font-family: 'Editorial';
    font-style: normal;
    src:
        url(../fonts/PPEditorialNew-Regular.woff) format("woff"),
        url(../fonts/PPEditorialNew-Regular.woff2) format("woff2");
}

@font-face {
    font-family: 'Editorial';
    font-style: italic;
    src:
        url(../fonts/PPEditorialNew-Italic.woff) format("woff"),
        url(../fonts/PPEditorialNew-Italic.woff2) format("woff2");
}