body {
  @apply antialiased;
}

.landingLines {
  @apply h-0.5 ml-5 md:ml-14 mb-2 flex-1 bg-offWhite;
}

.padBetween {
  @apply my-20 md:my-[50vh];
}

/* purgecss start ignore */

.twic-blur, .twic-loading {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
}

  .twic-done {
    opacity: 1;
  }

  .supermarquee-container {
    @apply h-full;
  }

  :where(media-player[data-view-type='video']:not([data-fullscreen])) :where(media-outlet, video, media-poster, div[part='scrim']) {
    @apply rounded-[0px] border-none border-transparent;
  }

  video {
    @apply object-contain w-full h-full !important;
  }

  media-player {
    @apply bg-transparent !important;
  }

  media-outlet {
    @apply bg-transparent !important;
  }

/* purgecss end ignore */
